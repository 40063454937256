class CardCarousel {
	block;

	constructor(block) {
		this.block = block;
		this.init();
	}

	init() {
		this.swiper = new Swiper(this.block.querySelector('.swiper'), {
            slidesPerView: 1.25,
            spaceBetween: 16,
            navigation: {
                nextEl: this.block.querySelector('.swiper-button-next'),
                prevEl: this.block.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.1,
                },
                768: {
                    spaceBetween: 25,
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
            },
        });
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-card-carousel').forEach((block) => {
		new CardCarousel(block);
	})
});
